import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';

@Component({
  selector: 'app-order-preference-dialog',
  standalone: true,
  imports: [TranslateModule, MatCheckboxModule, FormsModule, ReactiveFormsModule, NgIf],
  templateUrl: './order-preference-dialog.component.html',
  styleUrls: ['./order-preference-dialog.component.scss'],
})
export class OrderPreferenceDialogComponent implements OnInit {
  public isDiscountChecked = true;

  public isFreeShippingChecked = false;

  public orderPreferenceForm = new FormGroup({
    isFreeShippingPreferred: new FormControl(false),
    isProfitDiscountPreferred: new FormControl(true),
    preferredProfitDiscountPercentage: new FormControl(20),
  });

  constructor(
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _dialogRef: MatDialogRef<OrderPreferenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}

  ngOnInit(): void {
    this.orderPreferenceForm.patchValue(this.data);
    if (this.data?.isProfitDiscountPreferred) {
      this.isDiscountChecked = true;
    } else {
      this.isDiscountChecked = false;
    }
  }

  discountChecked(event: MatCheckboxChange): void {
    this.isDiscountChecked = event.checked;
    if (this.isDiscountChecked) {
      this.orderPreferenceForm
        .get('preferredProfitDiscountPercentage')!
        .setValidators([Validators.required]);
      this.orderPreferenceForm.get('preferredProfitDiscountPercentage')!.updateValueAndValidity();
    } else {
      this.orderPreferenceForm.get('preferredProfitDiscountPercentage')!.setValidators([]);
      this.orderPreferenceForm.get('preferredProfitDiscountPercentage')!.updateValueAndValidity();
      this.orderPreferenceForm.get('preferredProfitDiscountPercentage')!.setValue(20);
    }
  }

  freeShippingChecked(event: MatCheckboxChange): void {
    this.isFreeShippingChecked = event.checked;
  }

  clearSelections() {
    this._logMixpanelEventUseCase.execute({
      eventName: 'order_preference_cancel_clicked',
    });
    this.orderPreferenceForm.reset();
    this.isDiscountChecked = false;
    this._dialogRef.close();
  }

  onSubmit() {
    this._logMixpanelEventUseCase.execute({
      eventName: 'order_preference_save_changes_clicked',
      payload: {
        isFreeShippingPreferred: this.orderPreferenceForm.get('isFreeShippingPreferred')!.value,
        isProfitDiscountPreferred: this.orderPreferenceForm.get('isProfitDiscountPreferred')!.value,
        preferredProfitDiscountPercentage: this.orderPreferenceForm.get(
          'preferredProfitDiscountPercentage',
        )!.value,
      },
    });
    this._dialogRef.close({ data: this.orderPreferenceForm.value });
  }
}
