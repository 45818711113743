import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MerchantOrderPreferencesModel } from 'src/app/core/domain/order-model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { OrderPreferenceDialogComponent } from './order-preference-dialog/order-preference-dialog.component';

@Component({
  selector: 'app-order-preference',
  standalone: true,
  imports: [TranslateModule, NgIf, LoaderComponent, NgClass],
  templateUrl: './order-preference.component.html',
  styleUrls: ['./order-preference.component.scss'],
})
export class OrderPreferenceComponent implements OnInit {
  public assetsPath = '../../../../assets/img/order-preference/';

  @Input() shouldShowEditButton? = true;

  @Input() orderPreferenceFromOrderDetails?: MerchantOrderPreferencesModel;

  @Input() orderPreferenceSource?: string;

  @Output() orderPreferenceProperties: EventEmitter<MerchantOrderPreferencesModel> =
    new EventEmitter();

  public orderPreferenceOptions: MerchantOrderPreferencesModel = {
    isFreeShippingPreferred: false,
    isProfitDiscountPreferred: true,
    preferredProfitDiscountPercentage: 20,
  };

  public percentage = this.orderPreferenceOptions.preferredProfitDiscountPercentage;

  constructor(
    private _dialog: MatDialog,
    private _siteTranslateService: SiteTranslateService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {}

  ngOnInit(): void {
    if (this.orderPreferenceFromOrderDetails) {
      this.orderPreferenceOptions = this.orderPreferenceFromOrderDetails;
      this.percentage = this.orderPreferenceOptions.preferredProfitDiscountPercentage;
    }
  }

  public openOrderPreferenceDialog() {
    this._logMixpanelEventUseCase.execute({
      eventName: 'order_preference_edit_button_clicked',
      payload: {
        source: this.orderPreferenceSource,
      },
    });
    const dialogRef = this._dialog.open(OrderPreferenceDialogComponent, {
      width: '900px',
      panelClass: 'linked-store-dialog',
      direction: this._siteTranslateService.currentDir,
      data: this.orderPreferenceOptions,
    });
    dialogRef.afterClosed().subscribe(({ data }) => {
      this.orderPreferenceOptions = data;
      this.percentage = this.orderPreferenceOptions.preferredProfitDiscountPercentage;
      this.orderPreferenceProperties.emit(data);
    });
  }
}
