<div class="order-preference" [ngClass]="{ 'no-edit-btn': !shouldShowEditButton }">
  <div class="order-preference__header">
    <p class="body1--bold content-main-color">
      {{ 'ORDER_PREFERENCE.HEADER' | translate }}
      <span class="beta-text caption2--medium">{{ 'ORDER_PREFERENCE.BETA' | translate }}</span>
    </p>
    <div
      class="same-row edit-container"
      *ngIf="shouldShowEditButton"
      (click)="openOrderPreferenceDialog()"
    >
      <img src="{{ assetsPath + 'edit.svg' }}" />
      <p class="caption1--bold content-main-color">
        {{ 'ORDER_PREFERENCE.EDIT' | translate }}
      </p>
    </div>
  </div>
  <div class="order-preference__header__lower">
    <div class="same-row">
      <img
        src="{{ assetsPath }}{{
          orderPreferenceOptions?.isFreeShippingPreferred ? 'tick.svg' : 'check.svg'
        }}"
      />
      <p class="caption1--bold content-main-color">
        {{ 'ORDER_PREFERENCE.FREE_SHIPPING' | translate }}
      </p>
      <p
        class="caption1--bold content-medium-color"
        *ngIf="!orderPreferenceOptions?.isFreeShippingPreferred"
      >
        {{ 'ORDER_PREFERENCE.DISABLED' | translate }}
      </p>
    </div>
    <div class="same-row">
      <img
        src="{{ assetsPath }}{{
          orderPreferenceOptions?.isProfitDiscountPreferred ? 'tick.svg' : 'check.svg'
        }}"
      />
      <div>
        <p class="caption1--bold content-main-color">
          {{ 'ORDER_PREFERENCE.DISCOUNT_APPLIED' | translate }}
        </p>
        <p
          class="caption1--bold content-medium-color"
          *ngIf="orderPreferenceOptions?.isProfitDiscountPreferred"
        >
          {{ 'ORDER_PREFERENCE.NO_DISCOUNT' | translate : { percentage } }}
        </p>
      </div>
      <p
        class="caption1--bold content-medium-color"
        *ngIf="!orderPreferenceOptions?.isProfitDiscountPreferred"
      >
        {{ 'ORDER_PREFERENCE.DISABLED' | translate }}
      </p>
    </div>
  </div>
</div>
