import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { country } from '@features/country/data';
import { LocalizedComponent } from 'app/presentation/base/localized.component';

@Component({
  selector: 'app-info-alert',
  templateUrl: './info-alert.component.html',
  styleUrls: ['./info-alert.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class InfoAlertComponent extends LocalizedComponent {
  isVisible = true;

  selectedCountry = country;

  closeAlert(): void {
    this.isVisible = false;
  }
}
