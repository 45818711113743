export enum ImageSize {
  small = '100x100',
  medium = '160x160',
  large = '360x360',
}

export const getSizedImage = (url: string, size: ImageSize): string => {
  if (!url) return '';

  const imageResizeRegex = /(.+\.com)\/(.+)/;
  const noSizeUrl = url.replace(/\/\d{3}x\d{3}\//g, '/');
  const regexMatch = noSizeUrl.match(imageResizeRegex);
  if (!(regexMatch?.length! >= 3)) {
    return url;
  }
  const imageUrl = regexMatch![1];
  const imageName = regexMatch![2];
  const sizedImageUrl = `${imageUrl}/${size}/${imageName}`;
  return sizedImageUrl;
};
