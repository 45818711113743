<div class="info-alert" *ngIf="isVisible && selectedCountry.code === 'IRQ'">
  <div class="text">
    <img src="assets/img/info-circle.svg" alt="info" class="info-icon" />

    <p>
      {{ trans('IRAQ_INFO_ALERT') }}
    </p>
  </div>

  <button class="close-button btn" (click)="closeAlert()">
    <img src="assets/img/close-alert.svg" alt="close" />
  </button>
</div>
