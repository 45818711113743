<form [formGroup]="orderPreferenceForm">
  <div class="order-preference-dialog">
    <div>
      <p class="heading2--bold content-main-color">
        {{ 'ORDER_PREFERENCE.HEADER' | translate }}
        <span class="beta-text caption2--medium">{{ 'ORDER_PREFERENCE.BETA' | translate }}</span>
      </p>
      <p class="caption2--regular content-medium-color">
        {{ 'ORDER_PREFERENCE.DIALOG.HEADER_DESCRIPTION' | translate }}
      </p>
    </div>
    <div class="check-container">
      <mat-checkbox
        formControlName="isFreeShippingPreferred"
        (change)="freeShippingChecked($event)"
      ></mat-checkbox>
      <div>
        <p class="caption1--bold content-main-color">
          {{ 'ORDER_PREFERENCE.FREE_SHIPPING' | translate }}
        </p>
        <p class="caption2--regular content-medium-color">
          {{ 'ORDER_PREFERENCE.DIALOG.FREE_SHIPPING_DESCRIPTION' | translate }}
        </p>
      </div>
    </div>
    <div class="tip-container" *ngIf="isFreeShippingChecked">
      <img src="../../../../../assets/img/info-icon.svg" />
      <p class="body2--regular content-main-color">
        {{ 'ORDER_PREFERENCE.DIALOG.INFO_TIP' | translate }}
      </p>
    </div>
    <div class="check-container">
      <mat-checkbox
        formControlName="isProfitDiscountPreferred"
        (change)="discountChecked($event)"
      ></mat-checkbox>
      <div>
        <p class="caption1--bold content-main-color">
          {{ 'ORDER_PREFERENCE.DISCOUNT_APPLIED' | translate }}
        </p>
        <p class="caption2--regular content-medium-color" *ngIf="isDiscountChecked">
          {{ 'ORDER_PREFERENCE.DIALOG.DISCOUNT_DESCRIPTION' | translate }}
        </p>
        <p class="caption2--regular content-medium-color" *ngIf="!isDiscountChecked">
          {{ 'ORDER_PREFERENCE.DIALOG.DISCOUNT_DESCRIPTION_INCENTIVE' | translate }}
        </p>
      </div>
    </div>
    <div class="discount-input-container" *ngIf="isDiscountChecked">
      <p class="caption2--regular content-main-color">
        {{ 'ORDER_PREFERENCE.DIALOG.NO_DISCOUNT_THAN' | translate }}
      </p>
      <input
        class="discount-input-container__discount-input caption1--bold"
        type="number"
        min="1"
        max="100"
        required
        step="1"
        formControlName="preferredProfitDiscountPercentage"
      />
      <p class="discount-input-container__profit caption2--regular content-medium-color">
        {{ 'ORDER_PREFERENCE.DIALOG.FROM_PROFIT' | translate }}
      </p>
      <p class="discount-input-container__percentage caption2--regular content-main-color">%</p>
    </div>
    <div class="dialog-buttons-container">
      <button
        class="body2--bold dialog-button dialog-button__confirm"
        (click)="onSubmit()"
        [disabled]="orderPreferenceForm.invalid"
      >
        {{ 'ORDER_PREFERENCE.DIALOG.SAVE' | translate }}
      </button>
      <button class="body2--bold dialog-button dialog-button__cancel" (click)="clearSelections()">
        {{ 'ORDER_PREFERENCE.DIALOG.CANCEL' | translate }}
      </button>
    </div>
  </div>
</form>
